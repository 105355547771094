import makeApiService from "./api-service";

let apiUrl = "https://bookings.willowlife.lh/api/v1", apiOrigin = "https://localhost";

// switch (window.location.hostname) {
//     case "bookings-admin.willowlife.co.uk":
//         apiUrl = "https://bookings.willowlife.co.uk/api/v1";
//         apiOrigin = "https://bookings-admin.willowlife.co.uk";
//         break;
// }

apiUrl = "https://bookings.willowlife.co.uk/api/v1";
apiOrigin = "https://bookings-admin.willowlife.co.uk";

const apiService = makeApiService(apiUrl);
apiService.setHeader("X-Api-Origin", apiOrigin);
export default apiService;
