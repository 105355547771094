<template>
    <div class="api-status">
        <b-container class="mt-3">
            <b-row>
                <b-col >
                    <h1>API Status</h1>
                </b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col><h5>API name</h5></b-col>
                <b-col><h5>Status</h5></b-col>
                <b-col><h5>Since</h5></b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col>PracticeHub</b-col>
                <b-col><yes-no-icons class="ml-3" :value="practiceHubIsUp" /></b-col>
                <b-col>{{ practiceHubSince }}</b-col>
            </b-row>
            <b-row class="mt-3">
                <b-col>Keap</b-col>
                <b-col><yes-no-icons class="ml-3" :value="keapIsUp" /></b-col>
                <b-col>{{ keapSince }}</b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
// import dayjs from "dayjs";
import YesNoIcons from "@/components/YesNoIcons.vue";
import { dateFormat } from "@/utils";
import { mapGetters } from "vuex";

export default {
    name: "ApiStatusView",
    components: { YesNoIcons },
    computed: {
        practiceHubStatus() { return this.apiStatus.practicehub; },
        keapStatus() { return this.apiStatus.keap; },
        practiceHubIsUp() { return this.practiceHubStatus.isUp ? true : false; },
        keapIsUp() { return this.keapStatus.isUp ? true : false; },
        practiceHubSince() { return dateFormat(this.apiStatus.practicehub.since, "DD/MM/YYYY HH:mm"); },
        keapSince() { return dateFormat(this.apiStatus.keap.since, "DD/MM/YYYY HH:mm"); },
        ...mapGetters(["apiStatus"])
    }
}
</script>
