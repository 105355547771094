<template>
    <div></div>
</template>

<script>
import { eventBus } from "@/main"

export default {
    name: "ToastHandler",
    created() {
        eventBus.$on("toast", (config) => {
            this.$bvToast.toast(config.message, {
                toaster: config.position || "b-toaster-bottom-center",
                variant: config.variant || "info",
                autoHideDelay: config.duration || 2000,
                solid: true,
                appendToast: false,
                noCloseButton: true
            })
        })
    }
}
</script>