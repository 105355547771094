<template>
    <div class="home">
        <b-container v-if="isLoggedIn" class="mt-3">
            <b-row>
                <b-col cols="12">
                    <h1>Welcome</h1>
                </b-col>
            </b-row>
        </b-container>
        <login-form v-if="!isLoggedIn"></login-form>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoginForm from "@/components/LoginForm.vue";

export default {
    name: "HomeView",
    components: {
        LoginForm
    },
    computed: {
        ...mapGetters(["isLoggedIn"])
    },
}
</script>
