import clone from "just-clone";

const dateStringsToObjects = (data, fieldNames) => {
    return data.map(record => {
        let r = cloneDeep(record);
        fieldNames.forEach(field => {
            if (record[field]) r[field] = new Date(record[field]);
        });
        return r;
    });
}

const urlWithSessionParams = (path, session) => {
    const hostname = window.location.hostname.replace(/\/+$/, '');
    path = path.replace(/\/+$/, '').replace(/^\/+/, '');//  Strip leading/trailing slash characters
    let params = (session.custom_url_json && session.custom_url_json.params) ? session.custom_url_json.params.reduce((acc, cur) => `${acc}&${cur.key}=${cur.value}`, '') : '';
    return `https://${hostname}/${path}/?sessionid=${session.code}${params}`;
};

const redirectWithSessionParams = (path, session) => {
    window.location.href = urlWithSessionParams(path, session);
};

const cloneDeep = obj => { return clone(obj); };//  Leaning on just-clone.clone() because it's hard to write code to clone a Date

const dateAdd = (date, amt, type) => {
    type = `${type ?? "ms"}`.toLowerCase();

    switch (type) {
        case "years":
        case "year":
        case "yr":
        case "y":
            date.setFullYear(date.getFullYear() + amt);
            break;
        case "months":
        case "month":
        case "mth":
        case "mon":
        case "mmm":
        case "mm":
            date.setMonth(date.getMonth() + amt);
            break;
        case "days":
        case "day":
        case "d":
            date.setTime(date.getTime() + (amt * 86400000));
            break;
        case "hours":
        case "hour":
        case "hr":
        case "h":
            date.setTime(date.getTime() + (amt * 3600000));
            break;
        case "minutes":
        case "minute":
        case "min":
        case "m":
            date.setTime(date.getTime() + (amt * 60000));
            break;
        case "seconds":
        case "second":
        case "sec":
        case "s":
            date.setTime(date.getTime() + (amt * 1000));
            break;
        default://  ms
            date.setTime(date.getTime() + amt);
            break;
    }

    return date;
};
const dateSub = (date, amt, type) => { return dateAdd(date, 0 - (amt ?? 0), type ?? "ms"); };

const dateFormat = (date, format) => {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let output;

    switch (format) {
        case "YYYY":
            return `${date.getFullYear()}`;
        case "YY":
            return `${date.getFullYear()}`.slice(-2);
        case "MMMM":
            return months[date.getMonth()];
        case "MMM":
            return months[date.getMonth()].slice(0, 3);
        case "MM":
            return `0${date.getMonth() + 1}`.slice(-2);
        case "DDDD":
            return days[date.getDay()];
        case "DDD":
            return days[date.getDay()].slice(0, 3);
        case "DD":
            return `0${date.getDate()}`.slice(-2);
        case "D":
            return `${date.getDate()}`;
        case "HH":
            return `0${date.getHours()}`.slice(-2);
        case "H":
            return `${date.getHours()}`;
        case "hh":
            output = date.getHours();
            return `0${output >= 13 ? output - 12 : output}`.slice(-2);
        case "h":
            output = date.getHours();
            return `${output >= 13 ? output - 12 : output}`;
        case "mm":
            return `0${date.getMinutes()}`.slice(-2);
        case "m":
            return `${date.getMinutes()}`;
        case "ss":
            return `0${date.getSeconds()}`.slice(-2);
        case "s":
            return `${date.getSeconds()}`;
        case "uuu":
            return `00${date.getMilliseconds()}`.slice(-3);
        case "uu":
            return `00${date.getMilliseconds()}`.slice(-3);
        case "u":
            return `${date.getMilliseconds()}`;
        case "A":
            return date.getHours() >= 12 ? "PM" : "AM";
        case "a":
            return date.getHours() >= 12 ? "pm" : "am";
        case "DD MMM YYYY":
            return `${dateFormat(date, "DD")} ${dateFormat(date, "MMM")} ${dateFormat(date, "YYYY")}`;
        case "DD/MM/YYYY":
            return `${dateFormat(date, "DD")}/${dateFormat(date, "MM")}/${dateFormat(date, "YYYY")}`;
        case "YYYY-MM-DD":
            return `${dateFormat(date, "YYYY")}-${dateFormat(date, "MM")}-${dateFormat(date, "DD")}`;
        case "ddd D MMM YYYY":
        case "DDD D MMM YYYY":
            return `${dateFormat(date, "DDD")} ${dateFormat(date, "D")} ${dateFormat(date, "MMM")} ${dateFormat(date, "YYYY")}`;
        case "dddd D MMM YYYY":
        case "DDDD D MMM YYYY":
            return `${dateFormat(date, "DDDD")} ${dateFormat(date, "D")} ${dateFormat(date, "MMM")} ${dateFormat(date, "YYYY")}`;
        case "dddd, D MMMM YYYY":
        case "DDDD, D MMMM YYYY":
            return `${dateFormat(date, "DDDD")}, ${dateFormat(date, "D")} ${dateFormat(date, "MMMM")} ${dateFormat(date, "YYYY")}`;
        case "dddd, D MMM - HH:mmA":
        case "DDDD, D MMM - HH:mmA":
            return `${dateFormat(date, "DDDD")}, ${dateFormat(date, "D")} ${dateFormat(date, "MMM")} - ${dateFormat(date, "HH")}:${dateFormat(date, "mm")}${dateFormat(date, "A")}`;
        case "h:mma":
        case "h:mmA":
            output = `${dateFormat(date, "h")}:${dateFormat(date, "mm")}${dateFormat(date, "a")}`;
            return format == "h:mmA" ? output.toUpperCase() : output;
        case "hh:mma":
        case "hh:mmA":
            output = `${dateFormat(date, "hh")}:${dateFormat(date, "mm")}${dateFormat(date, "a")}`;
            return format == "hh:mmA" ? output.toUpperCase() : output;
        case "HH:mm":
            return `${dateFormat(date, "HH")}:${dateFormat(date, "mm")}`;
        case "DD/MM/YYYY h:mma":
            return `${dateFormat(date, "DD/MM/YYYY")} ${dateFormat(date, "h:mma")}`;
        case "DD/MM/YYYY HH:mm":
            return `${dateFormat(date, "DD/MM/YYYY")} ${dateFormat(date, "HH:mm")}`;
        default:
            return `${dateFormat(date, "YYYY")}-${dateFormat(date, "MM")}-${dateFormat(date, "DD")} ${dateFormat(date, "HH")}:${dateFormat(date, "mm")}:${dateFormat(date, "ss")}`;
    }
};

export { dateStringsToObjects, dateAdd, dateSub, dateFormat, urlWithSessionParams, redirectWithSessionParams, cloneDeep };
