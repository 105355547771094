import { cloneDeep } from "lodash";

function dateFromString (dateString) {
    return dateString ? new Date(dateString) : null;
}

export default {
    dateStringsToObjects(data, fieldNames) {
        return data.map(record => {
            let r = cloneDeep(record);
            fieldNames.forEach(field => {
                if (record[field]) r[field] = dateFromString(record[field]);
            });
            return r;
        })
    }
};
