<template>
    <div>
        <b-navbar toggleable="lg" type="dark" variant="info">
            <b-navbar-brand to="/">Willow Bookings Admin</b-navbar-brand>

            <b-navbar-toggle v-if="isLoggedIn" target="nav-collapse"></b-navbar-toggle>

            <b-collapse v-if="isLoggedIn" id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item v-for="(route) in userRoutesLeft" :key="route.name" :to="route.path" class="ml-2 mr-2">
                        {{ route.meta.title }}</b-nav-item>
                </b-navbar-nav>

                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <b-nav-text class="mr-3">Logged in as: <strong>{{ userName }}</strong></b-nav-text>
                    <b-nav-item to="/logout" variant="light">Log out</b-nav-item>
                </b-navbar-nav>
            </b-collapse>

            <b-navbar-nav class="ml-auto">
                <b-nav-item to="/api-status" class="ml-2 mr-2">API status</b-nav-item>
            </b-navbar-nav>
        </b-navbar>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
    name: "NavBar",
    computed: {
        userName() { return this.user ? this.user.name : ""; },
        userRoutesLeft() {
            return this.userRoutes.filter(route => !((["logout", "home", "api-status"]).includes(route.name)));
        },
        userRouteRight() {
            return this.userRoutes.filter(route => (["logout"]).includes(route.name));
        },
        ...mapGetters(["userRoutes", "isLoggedIn", "user"])
    }
}
</script>
