<template>
    <b-container>
        <b-row class="mt-3">
            <b-col>
                <h2>Log in</h2>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="2"></b-col>
            <b-col cols="8">
                <b-row class="mt-3">
                    <b-col cols="3">
                        <label for="email">Email address *</label>
                    </b-col>
                    <b-col cols="9">              
                        <b-form-input class="bm10" id="email" type="email" placeholder="your@email.com" v-model="credentials.email" trim autocomplete="email"></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col cols="3">
                        <label for="email">Password *</label>
                    </b-col>
                    <b-col cols="9">              
                        <b-form-input type="password" id="password" v-model="credentials.password" autocomplete="current-password"></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col cols="3"></b-col>
                    <b-col cols="9">              
                        <b-button type="submit" class="mr-3" variant="primary" @click="submitLogin">Submit</b-button>
                        <b-button type="reset" @click="clearLoginForm">Clear</b-button>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="2"></b-col>
        </b-row>
    </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LoginForm',
  data() {
      return { credentials: { email: '', password: '' } }
  },
  computed: {
      ...mapGetters([])
  },
  methods: {
      clearLoginForm() {
          this.credentials.email = ''
          this.credentials.password = ''
      },
      submitLogin() {
          this.login(this.credentials).then(() => {
            if(this.$router.currentRoute.name != 'home') this.$router.push('/')
          })
      },
      ...mapActions(['login'])
  }
}
</script>

<style scoped>
    .mb10 {
        margin-bottom: 10px;
    }
</style>
