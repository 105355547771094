import axios from "axios";
import dayjs from "dayjs";

let http;

class ApiService {
    constructor(ax) {
        http = ax;
        //  Add an axios interceptor to turn date objects into date strings... without changing time zones
        http.interceptors.request.use(function (config) {
            try {
                if (!config.data) return config;

                for (var key in config.data) {
                    const value = config.data[key];

                    if (value instanceof Date) {
                        config.data[key] = dayjs(value).format("YYYY-MM-DD HH:mm:ss");
                    }
                }

                return config;
            }
            catch (e) {
                return config;
            }
        })
    }

    get(uri, params) { return http.get(uri, { params: params }); }
    post(uri, params) { return http.post(uri, params); }
    put(uri, params) { return http.put(uri, params); }
    delete(uri, params) { return http.delete(uri, params); }

    headers() { return http.defaults.headers; }

    setHeader(headerName, headerValue) {
        http.defaults.headers.common[headerName] = headerValue;
        return this;
    }
    deleteHeader(headerName) {
        http.defaults.headers.common[headerName] = null;
        return this;
    }
}

//  baseURL is the full base URL of the API, EG https://api.mydomain.com/v1
function makeApiService(baseURL) {
    return new ApiService(axios.create({ baseURL }));
}

export default makeApiService;
