<template>
    <b-icon-check-circle-fill v-if="value" variant="success" :style="iconSize"></b-icon-check-circle-fill>
    <b-icon-x-circle-fill variant="danger" :style="iconSize" v-else></b-icon-x-circle-fill>
</template>

<script>
export default {
    name: "YesNoIcons",
    computed: {
        iconSize() {
            return {
                width: `${this.size}px`,
                height: `${this.size}px`
            };
        }
    },
    props: {
        value: Boolean,
        size: {
            type: Number,
            default: 24
        }
    }
}
</script>