//  A tokenData object looks like this:
// {
//   token: data.access_token,
//   ttl: time in seconds from token creation to token expiry,
//   expiresAt: timestamp for expiry time (set in VusJS app),
//   user: user claims - { id: 123, name: My Name, permissions: [ permission1, permission2... ] }
// }

export default {
    auth: {
        user: null,
        apiToken: null,// null or a tokenData object (defined in mutations.js)
        apiTokenIntervalId: null,
        isLoading: false
    },
    apiStatus: {
        intervalId: null,
        practicehub: { isUp: false, since: null },
        keap: { isUp: false, since: null }
    },
    routes: [],
    error: {
        show: false,
        message: ''
    },
    clinics: {
        isLoading: false,
        data: []
    },
    dcs: {
        isLoading: false,
        data: [],
        dropdownDCs: []
    },
    dcBlockouts: {
        isLoading: false,
        data: []
    },
    clinicNPDists: {
        isLoading: false,
        data: [],
        dropdownDateRanges: []
    },
    customUrls: {
        isLoading: false,
        data: [],
        dropdownTypes: [
            { value: 'np-consult', text: 'NP Consult' }
        ]
    },
    slots: {
        isLoading: false,
        data: [],
        dc: false,
        thereAreOtherDCs: false
    },
    appointments: {
        isLoading: false,
        data: []
    },
    appointmentTypes: {
        isLoading: false,
        data: []
    },
    appointmentTypeCategories: {
        isLoading: false,
        data: []
    },
    stripeProducts: {
        isLoading: false,
        data: []
    },
    settings: {
        isLoading: false,
        data: []
    },
    apiErrors: {
        isLoading: false,
        data: []
    },
    fiveMinSlots: {
        isLoading: false,
        data: []
    },
    dcBookingsHistory: {
        isLoading: false,
        data: [],
        historyLengthWeeks: 3
    },
}
